<template>
  <div>
    <table-page data-name="list"
                ref="table"
                :search="search"
                @on-success="fetchSuccess"
                :params="{ activity_id: $route.query.id }"
                :request="getOrderList">
      <template slot="button">
        <FileExport :params="exportParams"
                    v-if="exportParams">导出数据</FileExport>
      </template>
      <el-table-column prop="tel"
                       show-overflow-tooltip
                       width="130"
                       label="手机号码"></el-table-column>
      <el-table-column prop="id_code"
                       width="170"
                       show-overflow-tooltip
                       label="身份证号"></el-table-column>
      <el-table-column prop="goods_name"
                       show-overflow-tooltip
                       label="购买商品"></el-table-column>
      <el-table-column prop="transaction_id"
                       show-overflow-tooltip
                       width="240"
                       label="微信订单号">
      </el-table-column>
      <el-table-column show-overflow-tooltip
                       width="100"
                       label="订单总金额">
        <template slot-scope="scope"
                  width="80">
          {{scope.row.total_amount/100}}元
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip
                       width="120"
                       label="实际支付金额">
        <template slot-scope="scope">
          {{scope.row.pay_amount/100}}元
        </template>
      </el-table-column>
      <el-table-column prop="unifiedordered_at"
                       show-overflow-tooltip
                       label="下单时间">
      </el-table-column>
      <el-table-column prop="time_end"
                       show-overflow-tooltip
                       width="150"
                       label="付款时间">
      </el-table-column>
      <el-table-column prop="verify_at"
                       width="150"
                       show-overflow-tooltip
                       label="核销时间">
      </el-table-column>
      <el-table-column show-overflow-tooltip
                       width="120"
                       label="订单状态">
        <template slot-scope="scope">
          <span>{{ scope.row.status | statusName}}</span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip
                       width="120"
                       label="反馈状态">
        <template slot-scope="scope">
          <span v-if="scope.row.is_push === 1">
            <el-badge is-dot
                      type="success"
                      class="mr-15"></el-badge>反馈成功
          </span>
          <span v-else>
            <el-badge is-dot
                      type="warning"
                      class="mr-15"></el-badge>未反馈
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="verify_res_message"
                       show-overflow-tooltip
                       label="反馈描述"></el-table-column>
      <el-table-column prop="marketer_name"
                       show-overflow-tooltip
                       label="关联员工"></el-table-column>
      <el-table-column prop="areas_name"
                       show-overflow-tooltip
                       width="220"
                       label="员工所属网点">
        <template slot-scope="scope">
          <span>
            {{
            `${scope.row.marketer_province || ''}${scope.row.marketer_city ||
            ''}${scope.row.marketer_district || ''}${scope.row
            .marketer_net || ''}`
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column v-if="$store.state.user.role === 'ADMIN'"
                       width="150px"
                       label="操作">
        <template slot-scope="scope">
          <el-button type="text"
                     v-if="scope.row.status !== 'VERIFY_SUCCESS' && (scope.row.status === 'PAY_SUCCESS'|| scope.row.status === 'REFUND_CHANGE') && scope.row.pay_amount/100>0"
                     class="mr-15"
                     @click="refund(scope)">退款</el-button>
          <el-button type="text"
                     v-if="!scope.row.logistics_no"
                     v-permission="['ADMIN']"
                     @click="changeAddress(scope)">修改地址</el-button>
        </template>
      </el-table-column>
    </table-page>
    <SetAddress ref="setAddress"
                @on-success="$refs.table.fetch()"
                :aid="$route.query.id" />
  </div>
</template>

<script>
import SetAddress from './components/SetAddress'
import TablePage from '@/components/TablePage'
import FileExport from '@/components/FileExport'
import { getOrderList, orderRefund } from '@/api/market'
export default {
  name: 'MemberAppointOrder',
  data () {
    return {
      getOrderList,
      dialogVisible: false,
      luckyQrcodesExport_url: '',
      luckyLogExport_url: '',
      exportParams: null,
      search: [
        {
          type: 'input',
          name: '手机号码',
          placeholder: '请输入手机号码',
          key: 'tel',
          value: ''
        }
      ]
    }
  },
  components: {
    TablePage,
    FileExport,
    SetAddress
  },
  filters: {
    statusName (name) {
      let text = ''
      switch (name) {
        case 'SUBMITED':
          text = '下单已提交'
          break
        case 'UNIFIED_ORDER_FAIL':
          text = '统一下单失败'
          break
        case 'UNIFIED_ORDER_SUCCESS':
          text = '统一下单成功'
          break
        case 'CANCEL':
          text = '取消订单'
          break
        case 'PAY_FAIL':
          text = '支付失败'
          break
        case 'PAY_SUCCESS':
          text = '支付成功'
          break
        case 'VERIFY_SUCCESS':
          text = '已核销'
          break
        case 'REFUND_SUBMITED':
          text = '已提交退款'
          break
        case 'REFUND_CHANGE':
          text = '退款失败'
          break
        case 'REFUND_PROCESSING':
          text = '退款中'
          break
        case 'REFUND_SUCCESS':
          text = '退款成功'
          break
        default:
          break
      }
      return text
    }
  },
  methods: {
    changeAddress (scope) {
      this.$refs.setAddress.showDialog(scope)
    },
    fetchSuccess (e) {
      this.exportParams = e.export
    },
    exportData (url) {
      window.open(url, '_blank')
    },
    refund (scope) {
      this.$confirm('确认退款', {
        type: 'warning'
      }).then(async () => {
        const res = await orderRefund({
          activity_id: this.$route.query.id,
          id: scope.row.id
        })
        if (res.meta.code === 0) {
          this.$notify({
            title: '成功',
            message: '退款成功',
            type: 'success'
          })
          this.$refs.table.fetch()
        }
      })
    }
  },
  mounted () {

  }
}
</script>

<style lang="less" scoped></style>
